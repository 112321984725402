

































import { loginStore } from '@/store'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TheMobileView extends Vue {
  /**
   * Proxy to short links of apps
   * Has condition for custom servers - move to corporate applications link
   */
  goToApps () {
    const href = window.FEATURES.custom_server
      ? window.location.origin + '/getcorpapp'
      : 'https://tada.team/getapp'
    window.location.href = href
  }

  /**
   * Set state of seen mobile screen and navigate to Login screen (back)
   */
  goToBrowser () {
    loginStore.actions.setMobileSeenState()
    this.$router.push({ name: 'Login' })
  }
}
